<template>
  <div v-loading.fullscreen.lock="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <h3>単体・コース追加 </h3>
      </div>
      <el-form
        ref="form"
        :model="form"
        label-width="180px"
        label-position="left"
      >
<!--        <el-form-item
          label="タイプ"
          prop="service_combo_type"
          class="el-label-customer start-required"
        >
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.service_combo_type">
              <el-radio
                v-for="(value, index) in service_combo_type"
                :key="index"
                :label="value.id"
                :value="value.id"
                >{{ value.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item
          label="利用店舗名"
          prop="store_id"
          class="el-label-customer start-required"
        >
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.store_id">
              <el-radio
                v-for="(value, index) in store"
                :key="index"
                :label="value.id"
                :value="value.id"
                >{{ value.name }}
              </el-radio>
&lt;!&ndash;              <el-radio :label="0" :value="0">すべて </el-radio>&ndash;&gt;
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item
          label="非表示のステータス"
          prop="block"
          class="el-label-customer start-required"
        >
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.block">
              <el-radio v-for="(value, index) in service_block"
                        :key="index" :label="value.id" :class="value.className">{{ value.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>-->

        <el-form-item
          label="メニューのステータス"
          prop="status"
          class="el-label-customer start-required"
        >
          <div class="el-checkbox-width">
            <el-radio-group v-model="form.status">
              <el-radio v-for="(value, index) in status" :key="index" :class="value.className"
                        :label="value.id" :value="value.id">{{ value.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

<!--        <el-form-item label="表示順" prop="name" class="el-label-customer start-required">
          <el-input-number
            v-model="form.display_number"
            placeholder=""
            :min="1"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item v-if="form.service_combo_type === service_combo_type[0].id" label="カテゴリー" prop="category_id"
                      class="el-label-customer start-required">
          <el-select v-model="form.category_id" placeholder="選択してください">
            <el-option
              v-for="item in category"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="メニュー名" prop="name" class="el-label-customer start-required">
          <el-input
            v-model="form.name"
            placeholder="入力してください"
            class="input-width input-name"
            maxlength="256"
          >
          </el-input>
        </el-form-item>-->

        <el-form-item
          label="画像"
          prop="image"
          class="el-label-customer start-required"
        >
          <div class="row">
            <div class="el-col el-col-24 el-col-md-24">
              <div class="el-col el-col-24 el-col-md-6 text-break-all"></div>
              <div class="el-col el-col-8 el-col-md-6">
                <div class="">
                  <label class="file-drag">
                    <div v-if="!form.image" class="start">
                      <div @dragover.prevent @drop="onDrop" style="width: 350px; height: 350px; border: 1px solid" class="d-flex align-items-center justify-content-center">
                        <div>
                          <div class="d-flex justify-content-center">
                            <i class="el-icon-upload icon-upload-service-combo"></i>
                          </div>
                          <div class="description-image-service-combo">ドラッグ＆ドロップしてください。</div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="form.image" class="start">
                      <div class="">
                        <img  @dragover.prevent @drop="onDrop"
                          :src="form.image"
                          class="img-responsive"
                          style="width: 350px; height: 350px"
                        />
                      </div>
                    </div>
                  </label>
                  <input
                    :id="'file-upload'"
                    style="display: none"
                    type="file"
                    @change="onImageChange"
                    ref="fileInput"
                  />
                </div>
              </div>
              <div
                v-if="form.imageErr.length"
                class="el-col el-col-8 el-col-md-6"
              >
                <div v-if="form.imageErr.length" class="">
                  <p class="error-text mt-3">{{ form.imageErr[0] }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="メニュータイプ"
          prop="parent"
          class="el-label-customer start-required"
        >
          <div class="row">
            <div class="col-sm-3">施術時間</div>
            <div class="col-sm-3">料金</div>
            <div class="col-sm-6"></div>
          </div>
          <div v-for="(value, index) in form.process" :key="index" class="row pt-3" style="margin-bottom: 3px">
            <div class="col-sm-3">
              <el-form-item :prop="'process.'+index+'.use_time'">
                <el-input v-model="form.process[index].use_time" maxlength="9" @change="changeNumberType(index, 'use_time')"
                          placeholder="入力してください" class="input-width">
                  <template slot="append">分</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col-sm-3">
              <el-form-item :prop="'process.'+index+'.price'">
                <el-input v-model="form.process[index].price" maxlength="11" @change="changeNumberType(index, 'price')"
                          placeholder="入力してください" class="input-width">
                  <template slot="append">円</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col-sm-6">
              <label @click="deleteProcess(index)" class=" btn btn-danger btn-delete-combo" v-if="index !== 0"> X </label>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="form.process.length < 20">

          <el-form-item class="m-btn-add-shift-time pt-4" >
            <el-button type="text" @click="addProcess" class="btn-add-shift-time">
              <i class="el-icon-plus icon-add-tab"></i>追加する
            </el-button>
          </el-form-item>
        </el-form-item>

        <el-form-item v-if="form.service_combo_type !== service_combo_type[0].id" label="カテゴリー選択" prop="service_id" class="el-label-customer start-required">
          <el-checkbox-group v-model="form.service_id">
            <el-card v-for="(value, index) in category" :key="index" class="box-card">
              <div slot="header">
                <h5 style="font-weight: bold">{{ value.name }}</h5>
              </div>
              <div>
                <el-checkbox v-for="(v, i) in value.service" :key="i" :label="v.id">{{ v.name }}</el-checkbox>
              </div>
            </el-card>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="メニュー内容" prop="content" class="el-label-customer">
          <el-input
            type="textarea"
            v-model="form.content"
            class="input-width"
            maxlength="256"
            placeholder="入力してください"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="メニュー詳細"
          prop="detail"
          class="el-label-customer start-required"
        >
          <ckeditor v-model="form.detail" :config="editorConfig"></ckeditor>
        </el-form-item>

        <el-form-item>
          <button-common  v-on:cancleCreateOrCancelEdit="$router.push({ name: 'service-list' })" v-on:submitCreateOrCancleEdit="createComboService" type="create-edit" btnOk="追加" btnCancel="キャンセル">
          </button-common>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as CONSTANT from '../../config/constant';
import { ServicesService } from '../../services/services.service';
import { API_URL } from '../../../src/.env.js';

export default {
  name: 'Create',
  props: {},
  data () {
    return {
      editorConfig: {
        extraAllowedContent: 'h3{clear};h2{line-height};h2 h3{margin-left,margin-top}',
        allowedContent: true,
        language: 'ja',
        // Adding drag and drop image upload.
        extraPlugins: 'image2',
        uploadUrl: API_URL + '/upload-image',
        filebrowserUploadUrl: API_URL + '/upload-image',
        filebrowserImageUploadUrl: API_URL + '/upload-image',
        image2_disableResizer: false,
        height: 300,
        removeDialogTabs: 'image:advanced;link:advanced',
        removeButtons: 'PasteFromWord'
      },
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false,
      service_combo_type: CONSTANT.SERVICE_COMBO,
      store: [],
      service_block: CONSTANT.BLOCK,
      status: CONSTANT.STATUS,
      category: [],
      form: {
        category_id: 2,
        store_id: 1,
        block: 0,
        status: null,
        name: 'MenuDetail',
        image: '',
        imageErr: [],
        process: [
          {
            use_time: '',
            price: ''
          }
        ],
        content: null,
        detail: '',
        service_combo_type: 1,
        service_id: [],
        display_number: 1
      }
    }
  },
  created () {
    this.getListStoreCategory()
  },
  methods: {
    onImageChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createImage(files[0])
    },

    createImage (file) {
      this.form.imageErr = [];
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.form.image = e.target.result;
      };
      this.form.image = file;

      reader.readAsDataURL(file)
    },

    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    async getListStoreCategory () {
      try {
        const { data } = await ServicesService.StoreCategory();
        this.category = data.category;
        this.store = data.store;
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        }
      }
    },

    async createComboService () {
      try {
        this.loading = true;
        let image = null
        if (this.form.image !== '' && this.form.image !== null) {
          image = this.dataURLtoFile(this.form.image, '1.png');
        }
        this.$refs.form.clearValidate(this.form)
        await ServicesService.createServiceBlock(this.form.service_combo_type, this.form, image);
        this.$notify.success({
          title: '成功',
          message: '作成できました。',
          showClose: false
        });
        this.$router.push({ name: 'service-list' })
      } catch (error) {
        this.loading = false
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form')
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          })
        }
      }
    },

    deleteProcess (id) {
      this.form.process.splice(id, 1)
    },

    addProcess () {
      const data = {
        use_time: '',
        price: ''
      }
      this.form.process.push(data);
    },

    formatNumber (number) {
      return new Intl.NumberFormat('ja-JP').format(number)
    },

    changeNumberType (index, type) {
      this.form.process[index][type] = this.changeNumber(this.form.process[index][type])
    },
    onDrop (e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.createImage(files[0])
    }
  }
}
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
input[type=file] {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}

.clearfix:after {
  clear: both
}
::v-deep .el-select .el-input{
  width: 320px !important;
}
::v-deep .file-drag{
  width: 100%;
}
.add-process-btn{
  border: none;
  background-color: Transparent;
  color: green;
  width: fit-content;
}
.btn-width {
  width: 200px !important;
}
.el-icon-plus {
  border: 1px solid;
}

.input-name {
  width: 50%;
  min-width: 500px;
}
.valid_img {
  font-size: 12px;
}
</style>
