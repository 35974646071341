<template>
  <div>
    <el-dialog
      class="customer-dialog-create"
      :visible.sync="dialog"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :closeOnClickModal="false"
      center
    >
      <el-form :model="form" ref="form" label-width="150px" class="demo-form mb-5" label-position="left">

<!--        <el-form-item prop="parent" label-width="0px" class="">
          <el-form-item class="el-label-customer" label="店舗" prop="store_id">
            <el-select class="w&#45;&#45;100 bg-gray-item" v-model="form.store_id"
                       placeholder="選択してください">
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>-->

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col>
            <el-form-item class="el-label-customer start-required" label="氏名" prop="name">
              <el-input maxlength="200" placeholder="氏名を入力してください" class="" v-model="form.name" @input.native="(e)=>{lengthInput(e, 200)}"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col>
            <el-form-item class="el-label-customer" label="性別" prop="gender">
              <div class="el-checkbox-width">
                <el-radio-group v-model="form.gender">
                  <el-radio v-for="gender in constants.GENDER" :key="gender.value" :label="gender.value+''">{{ gender.label }}</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col>
            <el-form-item class="el-label-customer" label="生年月日" prop="birthday">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                format="yyyy/MM/dd"
                placeholder="生年月日を入力してください"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
            <el-form-item class="el-label-customer start-required" label="電話番号" prop="phone">
              <el-input maxlength="20" placeholder="半角数字を入力してください" v-model="form.phone"></el-input>
            </el-form-item>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
            <el-form-item class="el-label-customer start-required" label="メールアドレス" prop="email">
              <el-input maxlength="100" placeholder="メールアドレスを入力してください" v-model="form.email" type="email" ></el-input>
            </el-form-item>
        </el-form-item>

        <el-form-item prop="parent" label-width="0px" class="  ">
            <el-form-item class="el-label-customer start-required" label="パスワード" prop="password">
              <el-input maxlength="12" placeholder="パスワードを入力してください" v-model="form.password" show-password></el-input>
            </el-form-item>
        </el-form-item>

        <div class="row mt-4 pt-5">
          <div class="col-4 offset-4">
            <div class="d-flex justify-content-between">
              <div class="el-cusomer-reset-register">
                <el-button @click="handleClose">
                  キャンセル
                </el-button>
              </div>
              <div class="el-cusomer-search-register">
                <el-button class="bg-primary text-white" @click="registerStaff">登録</el-button>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as constants from '@/config/constant';
import { StaffService } from '@/services/staff.service';

export default {
  name: 'Create',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    storeList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      form: {
        name: '',
        gender: '1',
        birthday: '',
        phone: '',
        email: '',
        store_id: 1,
        password: ''
      },
      constants: constants
    }
  },

  methods: {
    resetForm () {
      this.form.name = ''
      this.form.gender = '1'
      this.form.birthday = ''
      this.form.phone = ''
      this.form.email = ''
      this.form.store_id = 1
      this.form.password = ''
    },
    lengthInput (e, maxLength) {
      if (e.target.value.length > maxLength) {
        e.target.value = e.target.value.substr(0, maxLength)
      }
    },
    handleClose () {
      this.resetForm()
      this.$refs.form.clearValidate(this.form)
      this.$emit('closeDialog')
    },
    async registerStaff () {
      try {
        this.$refs.form.clearValidate(this.form)
        this.loading = true;
        const staffData = {};
        staffData.phone = this.form.phone;
        staffData.email = this.form.email;
        staffData.password = this.form.password;
        staffData.store_id = this.form.store_id;
        staffData.name = this.form.name;
        if (this.form.birthday != null && this.form.birthday !== '') {
          staffData.birthday = this.form.birthday;
        }
        if (this.form.gender != null && this.form.gender !== '') {
          staffData.gender = this.form.gender;
        }
        const data = await StaffService.createStaff(staffData);
        if (data.status === 200) {
          this.$notify.success({
            title: '成功',
            message: '作成できました',
            showClose: false
          });
          this.resetForm('form')
          this.loading = false;
          this.$emit('registerSussce')
          this.$emit('closeDialog')
        }
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          });
        }
      }
    }
  }
}
</script>

<style scoped>
.w--100{
  width: 100%;
}
.file-upload-btn{
  text-decoration: underline;
  color: darkcyan;
}
.el-input {
  padding: 7px 0 ;
}
.el-radio-group {
  padding-top: 7px;
}
</style>
