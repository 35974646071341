<template>
  <div v-loading="loading">
    <div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <h3 style="font-weight: bold">スタッフリスト</h3>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" label-position="left">
          <el-form-item label="氏名" prop="name" class="el-label-customer">
            <el-col :md="12">
              <el-input v-model="ruleForm.name" placeholder="氏名"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="電話番号" prop="phone"  class="el-label-customer">
            <el-col :md="12">
              <el-input v-model="ruleForm.phone" maxlength="20" placeholder="電話番号"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="メールアドレス" prop="email" class="el-label-customer">
            <el-col :md="12">
              <el-input v-model="ruleForm.email" maxlength="100" placeholder="メールアドレス"></el-input>
            </el-col>
          </el-form-item>
<!--          <el-form-item label="店舗" prop="store_id" class="el-label-customer">
            <div class="el-checkbox-width">
              <el-checkbox-group v-model="ruleForm.store_id">
                <el-checkbox v-for="(value, index) in listStore" :key="index"
                             :label="value.id">{{ value.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-form-item>-->
          <el-form-item label="性別" prop="gender"  class="el-label-customer">
            <div class="el-checkbox-width">
              <el-checkbox-group v-model="ruleForm.gender">
                <el-checkbox v-for="(value, index) in constants.GENDER" :key="index"
                             :label="value.value">{{ value.label }}
                </el-checkbox>
                <!-- <el-checkbox :label="'0'">男性</el-checkbox>
                <el-checkbox :label="'1'">女性</el-checkbox> -->
              </el-checkbox-group>
            </div>
          </el-form-item>

          <button-common class="customer-confirm-delete" v-on:cancle="resetForm" v-on:submit="clickSearch" btnOk="検索" btnCancel="クリア">
          </button-common>

        </el-form>
        <el-divider></el-divider>

        <button-common v-on:cancleConfirm="openDialog" v-on:submitConfirm="openCreatePopup" type="confirm"
                       btnOk="アカウント追加" btnCancel="アカウント削除">
        </button-common>

        <div v-if="showErrDelete === true" class="mandatory" style="width: 100% !important;"> 削除するアカウントを選択してください。</div>
        <div>{{listCustomer.total}}件</div>
        <el-table
          ref="multipleTable"
          :data="listCustomer.data"
          :fit="true"
          :header-row-class-name="'background-table'"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column :resizable="false" label="編集" type="selection" align="center" width="85px">
          </el-table-column>
          <el-table-column :resizable="false" label="No." width="60px" align="center">
            <template slot-scope="scope" >
              <span>{{ scope.row.id ? scope.row.id : '' }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column :resizable="false" label="店舗" width="100px" align="center">
            <template slot-scope="scope" >
              <span>{{ scope.row.store.name ? scope.row.store.name : '' }}</span>
            </template>
          </el-table-column>-->
          <el-table-column :resizable="false" label="スタッフ名" width="100px" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.name ? scope.row.name : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="電話番号" align="center" width="120px">
            <template slot-scope="scope">
              <span>{{ scope.row.phone ? scope.row.phone : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="性別" width="75px" align="center">
            <template slot-scope="scope">
            <span>
              {{ scope.row.gender == constants.GENDER[0].value ? constants.GENDER[0].label  : constants.GENDER[1].label }}
            </span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="誕生日" width="100px" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.birthday ? scope.row.birthday : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="メールアドレス" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.email ? scope.row.email : '' }}</span>
            </template>
          </el-table-column>

          <el-table-column :resizable="false" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="$router.push({'name': 'staff-edit', params: {'staffId': scope.row.id}})" style="width: 150px">編集</el-button>
            </template>
          </el-table-column>

          <el-table-column :resizable="false" align="center" >
            <template slot-scope="scope">
              <el-button type="primary" @click="$router.push({name: 'shift-calendar', params: {staffId: scope.row.id}})">シフトカレンダー</el-button>
            </template>
          </el-table-column>

        </el-table>
        <div v-if="listCustomer.data" style="margin-top: 20px">
          <el-pagination
            :page-size="limit_size"
            :total="listCustomer.total"
            background
            :current-page="parseInt(form.page)"
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            align="right"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      width="20%"
      :closeOnClickModal="false"
    >
      <h5 class="textwidget text-center title-header-customer">本当に削除しますか?</h5>
        <span slot="footer" class="dialog-footer button-footer">
        <el-button @click="handleCloseDialog">キャンセル</el-button>
        <el-button type="danger" @click="deleteStaff"  id="w-button">確認</el-button>
      </span>
    </el-dialog>
    <create-staff @registerSussce="fetchData" @closeDialog="closePopup" :storeList="listStore" :dialog="showPopupCreate"></create-staff>
  </div>
</template>
<script>
import { StaffService } from '@/services/staff.service';
import { StoreService } from '@/services/store.service';
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin';
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin';
import * as constants from '@/config/constant';
import { LIMIT_PAGINATE } from '../../config/constant';
export default {
  components: {
  },
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  data () {
    return {
      form: {
        size: 1,
        page: 1
      },
      ruleForm: {
        name: '',
        phone: '',
        email: '',
        gender: [1],
        store_id: [1]
      },
      store_default: [1],
      rules: {},
      listCustomer: [],
      listStore: [],
      constants: constants,
      loading: false,
      checkEdit: false,
      customerCurrenId: 0,
      checkedDelete: [],
      dialogVisible: false,
      showPopupCreate: false,
      showErrDelete: false,
      limit_size: LIMIT_PAGINATE
    };
  },

  computed: {
    queryParam () {
      return this.$route.query;
    }
  },

  watch: {
    queryParam () {
      if (this.queryParam && Object.keys(this.queryParam).length === 0) {
        this.resetForm();
      } else {
        this.getDataFromQuery();
      }
      this.fetchData()
      this.getListStore();
    }
  },

  created () {
    this.getDataFromQuery();
    this.fetchData();
    this.getListStore();
  },
  methods: {
    resetForm () {
      this.ruleForm.name = ''
      this.ruleForm.phone = ''
      this.ruleForm.email = ''
      this.ruleForm.gender = [1]
      this.ruleForm.store_id = [1]
    },

    getDataFromQuery () {
      Object.keys(this.queryParam).map((value) => {
        if (value === 'gender' || value === 'store_id') {
          if (typeof this.queryParam[value] === 'object') {
            const data = this.queryParam[value]
            Object.keys(data).map((ele, index) => {
              data[index] = parseInt(data[index])
            })
            this.form[value] = [...data];
          } else {
            const data = this.queryParam[value]
            this.ruleForm[value].push(parseInt(data))
          }
        } else {
          if (value === 'page') {
            this.form[value] = this.queryParam[value];
          } else {
            this.ruleForm[value] = this.queryParam[value];
          }
        }
      });
    },

    getDataSearch (form) {
      const queryParam = this.queryParam;
      Object.keys(form).map(function (value) {
        if (typeof form[value] === 'object' && form[value].join()) {
          queryParam[value] = form[value].join();
        } else { queryParam[value] = form[value] }
      })
      this.queryParam.size = this.queryParam.size ? this.queryParam.size : this.limit_size;
      this.queryParam.page = this.queryParam.page ? this.queryParam.page : 1;
    },

    handleCloseDialog () {
      this.dialogVisible = false
    },

    openDialog () {
      if (this.checkedDelete.length === 0) {
        this.showErrDelete = true;
        var self = this;
        setTimeout(function () {
          self.showErrDelete = false;
        }, 2000);
        return true;
      }
      this.dialogVisible = true;
    },

    openCreatePopup () {
      this.showPopupCreate = true
    },

    async fetchData () {
      this.loading = true;
      try {
        const { data } = await StaffService.getLisStaff(
          this.prepareQueryParamsMixin(this.queryParam)
        );
        this.listCustomer = data;
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },

    async deleteStaff () {
      const ids = [];
      this.showErrDelete = false;
      this.checkedDelete.forEach(function (element) {
        ids.push(element.id)
      })
      const deleteId = { ids: ids }
      const data = await StaffService.deleteStaff(deleteId);
      this.dialogVisible = false
      if (data.status === 200) {
        this.$notify.success({
          title: '成功',
          message: '削除できました。',
          showClose: false
        });
        if (this.listCustomer.data.length === 1 && this.listCustomer.current_page > 1) {
          const page_delete = this.queryParam.page - 1;
          this.$router.push({
            name: 'staff-list',
            query: { ...this.queryParam, ...{ page: page_delete } }
          });
        } else {
          this.fetchData();
        }
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'Error',
          showClose: false
        });
      }
    },

    async getListStore () {
      this.loading = true;
      try {
        const { data } = await StoreService.getListStore()
        this.listStore = data.data;
      } catch (e) {
        alert(e.message)
      } finally {
        this.loading = false;
      }
    },

    clickSearch () {
      this.$router.push({
        name: 'staff-list',
        query: { ...this.queryParam, ...this.ruleForm, ...{ page: 1 } }
      }).catch(() => {
      });
    },
    handleCurrentChange (val) {
      this.checkedDelete = []
      this.form.page = val
      this.$router.push({
        name: 'staff-list',
        query: { ...this.queryParam, ...{ page: this.form.page } }
      }).catch(() => {
      });
    },
    handleSelectionChange (val) {
      this.checkedDelete = val
    },
    closePopup () {
      this.showPopupCreate = false
    }

  }
}
</script>
<style scoped>
#w-button{
  width: 120px;
  background-color: red;
}
</style>
