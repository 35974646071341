<template>
  <div>
    <el-dialog
      class="customer-dialog-create"
      :title="editCustomer === true ?  '顧客情報編集' : '顧客情報作成' "
      :visible.sync="dialog"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center
    >
      <el-form :model="form" ref="form" label-width="150px" class="demo-form mb-5" @keyup.enter.native="registerCustomer" label-position="left">
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer code_create_customer" label="顧客コード" prop="code">
              <el-aside class="title-header-customer">{{ form.code }}</el-aside>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="氏名" prop="name_kanji">
              <el-input maxlength="100" placeholder="氏名を入力してください" class="" v-model="form.name_kanji"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="フリガナ" prop="name_kana">
              <el-input maxlength="100" placeholder="氏名を入力してください" v-model="form.name_kana"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="性別" prop="gender">
              <el-radio-group v-model="form.gender">
                <el-radio  v-for="(item, index) in constants.GENDER" :label="item.value.toString()" :value="item.value" :key="index">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="生年月日" prop="birthday">
              <el-date-picker class="w-100 el-customer-date-picker"
                v-model="form.birthday"
                type="date"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                clear-icon=" ">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="電話番号" prop="phone" >
              <el-input maxlength="20" placeholder="半角数字を入力してください" v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="メールアドレス" prop="email">
              <el-input placeholder="" v-model="form.email"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer start-required" label="住所" prop="address">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
<!--        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer" label="よく通う店舗" prop="store_id">
              <el-select class="w&#45;&#45;100 bg-gray-item" v-model="form.store_id"
                         placeholder="選択してください">
                <el-option
                  v-for="(item, index) in storeList"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
                  {{item.name}}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>-->
        <div class="row">
          <div class="el-col el-col-24 el-col-md-24">
<!--            <div class="el-col el-col-24 el-col-md-6 text-break-all">
              <label class="el-form-item__label form-item-ran font-weight-bold" style="">契約書</label>
            </div>
            <div class="el-col el-col-8 el-col-md-6">
              <div class="">
                <input style="display: none" :id="'file-upload'" type="file" v-on:change="onImageChange">
                <label :for="'file-upload'"
                       class="file-drag">
                  <div class="start">
                    <div><span style=""
                      class="file-upload-btn">ファイル選択</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>-->
            <div v-if="form.contract" class="el-col el-col-8 el-col-md-4">
              <div class="" >
                <img :src="form.contract" class="img-responsive" height="200" width="200">
              </div>
            </div>
            <div v-else-if="form.oldImages" class="el-col el-col-8 el-col-md-4">
              <div class="" >
                <img :src="form.oldImages" class="img-responsive" height="200" width="200">
              </div>
            </div>
          </div>
          <div v-if="contractError.length">
            <div class="" v-if="contractError.length">
              <p class="error-text mt-3 text-center">{{ contractError[0] }}</p>
            </div>
          </div>
        </div>
        <el-form-item prop="parent" label-width="0px" class="  ">
          <el-col :md="20">
            <el-form-item class="el-label-customer" label="リマインダー送信先" prop="sent_contact">
              <el-checkbox-group v-model="form.sent_contact">
                <el-checkbox v-for="(value, index) in constants.SEND_CONTACT" :key="index"
                             :label="value.value">{{ value.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-form-item>
<!--        <el-form-item v-if="editCustomer" prop="parent" label-width="0px" class="  ">-->
<!--          <el-col :md="20">-->
<!--            <el-form-item class="el-label-customer start-required" label="ポイント残高" prop="points">-->
<!--              <el-input disabled maxlength="100" placeholder="氏名を入力してください" class="" v-model="form.points"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-form-item>-->

        <button-common v-if="editCustomer" v-on:cancleCreateOrCancelEdit="handleClose" v-on:submitCreateOrCancleEdit="updateCustomer" type="create-edit" btnOk="更新" btnCancel="キャンセル">
        </button-common>

        <button-common v-else v-on:cancleCreateOrCancelEdit="handleClose" v-on:submitCreateOrCancleEdit="registerCustomer" type="create-edit" btnOk="作成" btnCancel="キャンセル">
        </button-common>

      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as constants from '@/config/constant';
import { CustomerService } from '@/services/customer.service';
export default {
  name: 'Create',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    storeList: {
      type: Array,
      default: () => []
    },
    editCustomer: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    storeList (value) {
      if (value) {
        this.form.store_id = value[0] ? value[0].id : '';
      }
    },
    dialog (value) {
      this.$refs.form.clearValidate(this.form)
      if (value === true && this.editCustomer === false) {
        this.getCode()
      }
    },
    'form.sent_contact' () {
      if (this.form.sent_contact.length === 0) {
        this.form.sent_contact.push(constants.SEND_CONTACT[0].value);
      }
    },
    editCustomer (value) {
      this.contractError = []
      if (value === true) {
        this.getCustomerById()
      } else {
        this.form = {
          code: '',
          name: '',
          name_kana: '',
          gender: '1',
          birthday: '',
          phone: '',
          email: '',
          address: '',
          store_id: 1,
          sent_contact: [0],
          contract: '',
          image: '',
          points: 0,
          oldImages: ''
        }
      }
    }
  },
  data () {
    return {
      form: {
        code: '',
        name: '',
        name_kana: '',
        name_kanji: '',
        gender: '0',
        birthday: '',
        phone: '',
        email: '',
        address: '',
        store_id: 1,
        sent_contact: [0],
        contract: '',
        image: '',
        points: 0,
        oldImages: ''
      },
      constants: constants,
      contractError: []
    }
  },
  created () {
    this.getCode()
  },
  methods: {
    async getCustomerById () {
      this.loading = true;
      try {
        const { data } = await CustomerService.getCustommerById(this.customerId);
        const list_notice = [];
        if (data.notify_line === 1) {
          list_notice.push(0);
        }
        if (data.notify_email === 1) {
          list_notice.push(1);
        }
        this.form = {
          code: data.code,
          name: data.name,
          name_kana: data.name_kana,
          name_kanji: data.name_kanji,
          gender: data.gender.toString(),
          birthday: data.birthday,
          phone: data.phone,
          email: data.email,
          address: data.address,
          store_id: data.store_id,
          sent_contact: list_notice,
          contract: '',
          image: '',
          points: data.points
        }
        this.form.oldImages = data.contract ? data.contract : ''
      } catch (e) {
        this.$notify.error({
          title: 'Errors',
          message: e.message,
          showClose: false
        });
      } finally {
        this.loading = false;
      }
    },

    async registerCustomer () {
      try {
        this.$refs.form.clearValidate(this.form)
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        this.loading = true;
        const { data } = await CustomerService.createCustomer({ ...this.form }, null, false);
        if (data) {
          this.handleClose();
          this.dialog = false
          this.resetForm('form')
          this.$emit('registerSussce')
          this.$notify.success({
            title: '成功',
            message: '更新しました。',
            showClose: false
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          if (error.data.code) {
            this.getCode();
          }
          if (error.data.contract) {
            this.contractError = error.data.contract
          }
          this.showErrorMessage(error.data, 'form');
        }
      }
    },
    async updateCustomer () {
      try {
        this.$refs.form.clearValidate(this.form)
        const valid = await this.$refs.form.validate();
        if (!valid) {
          return;
        }
        this.loading = true;
        const { data } = await CustomerService.createCustomer({ ...this.form }, this.customerId, true);
        if (data) {
          this.handleClose();
          this.dialog = false
          this.resetForm('form')
          this.$emit('registerSussce')
          this.$notify.success({
            title: '成功',
            message: '成功しました。',
            showClose: false
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        } else {
          this.$notify.error({
            title: 'Error',
            message: error.message,
            showClose: false
          });
        }
      }
    },
    async getCode () {
      try {
        const { data } = await CustomerService.getCustomerCode();
        this.form.code = data
      } catch (error) {
        this.loading = false;
        if (error.code === 422) {
          this.showErrorMessage(error.data, 'form');
        }
      }
    },
    onImageChange (e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage (file) {
      this.contractError = []
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.form.contract = e.target.result;
        console.log(vm.form)
      };
      this.form.image = file;
      reader.readAsDataURL(file);
    },
    resetForm (formName) {
      this.form.contract = ''
      this.$refs[formName].resetFields();
    },

    clearForm () {
      this.form.code = ''
      this.form.name = ''
      this.form.name_kana = ''
      this.form.gender = '0'
      this.form.birthday = ''
      this.form.phone = ''
      this.form.email = ''
      this.form.address = ''
      this.form.store_id = 1
      this.form.sent_contact = [0]
      this.form.contract = ''
      this.form.image = ''
      this.form.oldImages = ''
      this.form.points = 0
    },

    handleClose () {
      this.contractError = []
      this.clearForm()
      this.$refs.form.clearValidate(this.form)
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>
.bg-primary{
  color: white;
}
.w--100{
  width: 100%;
}
.file-upload-btn{
  text-decoration: underline;
  color: darkcyan;
}
.el-input {
  padding: 7px 0;
}
</style>
